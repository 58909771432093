import { Link } from 'gatsby';
import React from 'react';

import { getArticleLink } from '../../helpers/link.helper';
import { Button } from '../Button';
import { Grid } from '../Grid';
import { Text, TextFontSize, TextFontWeight, TextType } from '../Text';

import './LatestArticle.component.scss';

interface LatestArticleProps {
  announcement: string;
  image: string;
  slug: string;
  title: string;  
}

export const LatestArticle: React.FC<LatestArticleProps> = ({
  slug,
  announcement,
  image,
  title,
}) => (
  <div className={'latest-article'}>
    <Grid.Row>
      <Grid.Column
        mobile={12}
        tablet={6}
        desktop={6}
      >
        <div className={'latest-article__image-wrapper'}>
          <img
            className={'latest-article__image'}
            src={image}
            alt={title}
          />
        </div>
      </Grid.Column>
      <Grid.Column
        mobile={12}
        tablet={6}
        desktop={6}
      >
        <div className={'latest-article__content'}>
          <div className={'latest-article__header'}>
            <div className={'latest-article__header-label'}>
              <Text size={TextFontSize.small}>
                Latest article
              </Text>
            </div>
            <Text
              type={TextType.H1}
              size={TextFontSize.huge}
              weight={TextFontWeight.bold}
            >
              {title}
            </Text>
          </div>
          <Text
            type={TextType.PARAGRAPH}
          >
            {announcement}
          </Text>
          <div className={'latest-article__button-wrapper'}>
            <Link to={getArticleLink(slug)}>
              <Button>
                Read more
              </Button>
            </Link>
          </div>
        </div>
      </Grid.Column>
    </Grid.Row>
  </div>
);
