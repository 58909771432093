import { Link } from 'gatsby';
import React from 'react';

import { Text, TextFontSize, TextFontWeight, TextType } from '../Text';
import { StrapiArticle } from '../../models/article';
import { getArticleLink } from '../../helpers/link.helper';

import './PostCard.component.scss';

export type PostCardProps = Pick<StrapiArticle, 'publishedAt' | 'image' | 'title' | 'reading_time' | 'slug' | 'category'>

export const PostCard: React.FC<PostCardProps> = ({
  category,
  image,
  publishedAt,
  reading_time,
  slug,
  title,
}) => (
  <Link to={getArticleLink(slug)}>
    <div className={'post-card'}>
      <div className={'post-card__thumbnail'}>
        <img
          className={'post-card__thumbnail-image'}
          src={image.url}
          alt={title}
        />
      </div>
      <div className={'post-card__content'}>
        {category?.name && (
          <div className={'post-card__category'}>
            {category.name}
          </div>
        )}
        <div className={'post-card__title'}>
          <Text
            size={TextFontSize.big}
            weight={TextFontWeight.bold}
            type={TextType.H3}
          >
            {title}
          </Text>
        </div>
        <div className={'post-card__footer'}>
          <Text size={TextFontSize.small}>
            {publishedAt}
          </Text>
          <Text size={TextFontSize.small}>
            {`${reading_time} min`}
          </Text>
        </div>
      </div>
    </div>
  </Link>
);