import React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

import { StrapiArticle } from '../../models/article';
import { Grid } from '../../components/Grid';
import { LatestArticle } from '../../components/LatestArticle';
import { PostCard } from '../../components/PostCard/PostCard.component';

import './IndexTemplate.component.scss';
import { formatToIso } from '../../helpers/date.helper';

interface IndexTemplateData {
  allStrapiArticle: {
    edges: {
      node: StrapiArticle
    }[];
  };
}

interface IndexTemplatePageContext {
  edges: {
    node: {
      slug: string;
      title: string;
    };
  }[];
}

interface IndexTemplateProps {
  data: IndexTemplateData;
  pageContext: IndexTemplatePageContext;
}

export const query = graphql`
  query {
    allStrapiArticle {
      edges {
        node {
          category {
            name
          }
          claps
          content {
            data {
              content
            }
          }
          published
          reading_time
          slug
          title,
          image {
            url
          }
          thumbnail {
            url
          }
        }
      }
    }
  }`;

const IndexTemplate: React.FC<IndexTemplateProps> = ({ data }) => {
  const sortedArticles = data.allStrapiArticle.edges.sort((a, b) => new Date(b.node.published).getTime() - new Date(a.node.published).getTime())
  const latestArticle = sortedArticles[0]

  return (
    <>
      <Helmet>
        <meta charSet={'utf-8'} />
        <title>{'Hellojs blog'}</title>
      </Helmet>
      <Grid.FullWidth>
        <LatestArticle
          title={latestArticle.node.title}
          announcement={latestArticle.node.announcment}
          image={latestArticle.node.image.url}
          slug={latestArticle.node.slug}
        />
      </Grid.FullWidth>
      <div className={'index-template__posts'}>
        <Grid.Row
          align={'start'}
          justify={'start'}
        >
          {[
            ...sortedArticles.map(({ node }) => ({
              category: node.category,
              slug: node.slug,
              publishedAt: node.published,
              image: node.image,
              title: node.title,
              reading_time: node.reading_time,
              thumbnail: node.thumbnail
            })
          )].map(({ category, publishedAt, slug, image, title, reading_time, thumbnail}) => (
            <Grid.Column
              key={`index-template__post--${title}`}
              mobile={12}
              tablet={6}
              desktop={4}
              spacing={'10px'}
            >
              <PostCard
                category={category}
                slug={slug}
                publishedAt={formatToIso(publishedAt)}
                image={thumbnail}
                title={title}
                reading_time={reading_time}
              />
            </Grid.Column>
          ))}
        </Grid.Row>
      </div>
    </>
  );
};

export default IndexTemplate;